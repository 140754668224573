@use "../abstracts" as *;

.productCard_primary {
  width: 100%;
  padding: 20px;
  height: 250px;
  position: relative;
  cursor: pointer;

  @include breakpoint("lg") {
    height: 350px;
  }

  @include breakpoint("lg") {
    height: 450px;
  }

  @include breakpoint("lg") {
    height: 550px;
  }

  h1 {
    margin-top: 5px;
    font-size: 1rem;

    @include breakpoint("lg") {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1rem;

    @include breakpoint("lg") {
      font-size: 1.4rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    border: 1px solid black;
  }
}

.productCard__isNewTag {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  background-color: color("gray", 200);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: black;
  z-index: 1;
}
