@use "../abstracts/" as *;

.allproducts {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.allproducts__title {
  width: 100%;
  height: 150px;
  display: flex;

  @include breakpoint("sm") {
    height: 250px;
  }

  @include breakpoint("md") {
    height: 350px;
  }

  @include breakpoint("lg") {
    height: 450px;
  }

  h1 {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 300;
    padding-left: 50px;
    white-space: nowrap;

    @include breakpoint("md") {
      font-size: 5rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image {
    width: 60%;
    height: 100%;
    z-index: -1;
  }
}

.allproducts__filters {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  height: 80px;
  border: 1px solid gainsboro;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .filter_box {
    width: 200px;
    height: 100%;
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgb(112, 112, 112);
    padding-left: 10px;
    appearance: none;
    background-color: white;
    background-image: url("https://d2c0vv5h4nuw6w.cloudfront.net/icons/expand-arrow.png");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 0.7em;
  }
  #filter {
    display: none;
  }
}

.allproducts__listings {
  width: 100%;
  display: flex;
}

.allproducts__leftPanelFilter__container {
  display: none;
  border: 1px solid gainsboro;

  @include breakpoint("lg") {
    display: block;
  }
}

.allproducts__leftPanelFilter {
  width: 400px;
  max-width: 500px;
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;

  &__title {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 2rem;
    border-bottom: 1px solid #cccccc;
  }
}

.allproducts__products {
  padding: 0px 20px;
  flex-grow: 1;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;

    @include breakpoint("md") {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint("xl") {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.filter-modal {
  display: none !important;
}

.allproducts__title__search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  // align-items: center;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  margin-top: 20px;
  // background-color: gainsboro;
  div {
    margin-top: 20px;
  }
}

// @include breakpoints.breakpoint(tablet) {
//   #filter {
//     display: flex !important;
//     width: 30px;
//     height: 30px;
//     margin-right: 20px;
//   }
//   .filter-modal {
//     display: none;
//     width: 100%;
//     display: flex !important;
//     flex-direction: column;
//     align-items: flex-end;

//     &__container {
//       width: 500px;
//       height: 50px;
//       background-color: white;
//       display: flex;
//       flex-direction: column;
//       align-items: flex-end;
//       justify-content: center;
//     }
//   }
//   .allproducts__title {
//     div {
//       font-size: 3.5rem;
//     }
//   }
//   .allproducts__products {
//     width: 100%;
//   }
//   .allproducts__leftPanelFilter__container {
//     display: none;
//   }
//   .allproducts__leftPanelFilter {
//     width: 100%;
//     background-color: white;
//   }
// }
// @include breakpoints.breakpoint(mobile) {
//   .allproducts__products {
//     &__grid {
//       padding: 0 10px;
//       grid-template-columns: repeat(1, 1fr);
//     }
//   }
//   .allproducts__title {
//     div {
//       padding: 0;
//       margin: 0;
//       height: auto;
//       width: 90%;
//       left: 10%;
//       top: 30%;
//       font-size: 4rem;
//       font-weight: 600;
//       position: absolute;
//     }
//     img {
//       width: 100%;
//     }
//     &__image {
//       width: 100%;
//       height: 100%;
//     }
//   }
// }
