@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

$ff-sans: "Open Sans", sans-serif;
$ff-intel: "Inter", sans-serif;

$font-sizes: (
  100: 0.75rem,
  200: 0.875rem,
  300: 1rem,
  400: 1.125rem,
  500: 1.25rem,
  600: 1.5rem,
  700: 1.875rem,
  800: 2.25rem,
  900: 3rem,
);
