.mark-delivered {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  color: white;
  background-color: #1c7c54;
  font-weight: 600;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
