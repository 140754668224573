@use "../abstracts/" as *;

.newarrivals {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newarrivals__container {
  width: 90%;
  height: 100%;
}

.newarrivals__title {
  justify-content: center;
  width: 100%;
  height: 150px;
  align-items: center;
  color: blanchedalmond;
  border-bottom: 1px solid gainsboro;
  font-size: 2rem;
  font-weight: 700;
  position: relative;

  @include breakpoint("sm") {
    font-size: 4rem;
    height: 250px;
    padding-bottom: 20px;
  }

  @include breakpoint("md") {
    font-size: 6rem;
    height: 350px;
    padding-bottom: 30px;
  }

  @include breakpoint("lg") {
    padding-top: 80px;
    font-size: 8rem;
    height: 200px;
    padding-bottom: 40px;
  }

  h1 {
    left: 50%;
    top: 30%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.newarrivals__listings {
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint("sm") {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint("md") {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint("lg") {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.newArrival__landingImg {
  width: 100%;
  height: 75%;
  object-fit: cover;
}
