.signup_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
}

.signup_input_container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid gainsboro;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  background-color: white;
  font-size: 20px;
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 50px;
}

.signup_title {
  margin-top: 15px;
}

.signup_label {
  width: 85%;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.signup_input_container input {
  width: 80%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  padding-left: 10px;
  font-weight: 600;
  margin-bottom: 15px;
}

.signup_input_container button {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 85%;
  height: 40px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  background-color: black;
  color: white;
  cursor: pointer;
}

.register_back_to_login {
  margin-bottom: 20px;
  font-size: 15px;
  display: flex;
}

.register_back_to_login div {
  margin-left: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .signup_input_container {
    width: 90vw;
    min-width: 350px;
  }
}
