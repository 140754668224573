@use "../abstracts/" as *;

@each $size-name, $size-value in $sizes {
  .margin {
    &-#{$size-name} {
      margin: $size-value;
    }

    &-left-#{$size-name} {
      margin-left: $size-value;
    }

    &-right-#{$size-name} {
      margin-right: $size-value;
    }

    &-top-#{$size-name} {
      margin-top: $size-value;
    }

    &-bottom-#{$size-name} {
      margin-bottom: $size-value;
    }

    &-block-#{$size-name} {
      margin-block: $size-value;
    }

    &-block-start-#{$size-name} {
      margin-block-start: $size-value;
    }

    &-block-end-#{$size-name} {
      margin-block-end: $size-value;
    }

    &-inline-#{$size-name} {
      margin-inline: $size-value;
    }

    &-inline-start-#{$size-name} {
      margin-inline-start: $size-value;
    }

    &-inline-end-#{$size-name} {
      margin-inline-end: $size-value;
    }
  }

  .padding {
    &-#{$size-name} {
      padding: $size-value;
    }

    &-left-#{$size-name} {
      padding-left: $size-value;
    }

    &-right-#{$size-name} {
      padding-right: $size-value;
    }

    &-top-#{$size-name} {
      padding-top: $size-value;
    }

    &-bottom-#{$size-name} {
      padding-bottom: $size-value;
    }

    &-block-#{$size-name} {
      padding-block: $size-value;
    }

    &-block-start-#{$size-name} {
      padding-block-start: $size-value;
    }

    &-block-end-#{$size-name} {
      padding-block-end: $size-value;
    }

    &-inline-#{$size-name} {
      padding-inline: $size-value;
    }

    &-inline-start-#{$size-name} {
      padding-inline-start: $size-value;
    }

    &-inline-end-#{$size-name} {
      padding-inline-end: $size-value;
    }
  }
}
