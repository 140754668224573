.cursor-pointer {
  cursor: pointer;
}

.hide-scrollbar {
  scrollbar-width: none; /* hide scrollbar */
  -ms-overflow-style: none; /* hide scrollbar in Edge and IE */
}

/* hide scrollbar thumb */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for WebKit browsers */
}

/* hide scrollbar thumb in Edge and IE */
.hide-scrollbar::-ms-scrollbar {
  display: none;
}

.shadow-4D {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.self-end {
  align-self: flex-end;
}

.grow-1 {
  flex-grow: 1;
}
