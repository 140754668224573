@use "../abstracts" as *;

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  &__container {
    display: flex;
    align-items: center;
  }

  &__account-text {
    width: 25px;
    margin: 0 5px;
    font-size: x-small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint(sm) {
      font-size: smaller;
      width: 35px;
    }

    @include breakpoint(md) {
      font-size: small;
      width: 45px;
    }

    @include breakpoint(lg) {
      font-size: medium;
      width: 75px;
    }
  }
}

.navigation-text {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: x-small;
  font-weight: 600;
  margin: 0px 6px;
  cursor: pointer;
  position: relative;

  &:first-of-type {
    margin-left: 20px;
  }

  @include breakpoint("sm") {
    font-size: small;
    margin: 0px 10px;
  }

  @include breakpoint("md") {
    font-size: medium;
    margin: 0px 15px;
  }

  @include breakpoint("lg") {
    font-size: medium;
    margin: 0px 20px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background-color: $colors-black;
    transition: height 0.1s ease-in-out;
  }

  &:hover::after {
    height: 2px;
  }
}
