.legal {
  padding: 20px;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    padding-block: 20px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    padding-block: 10px;
  }

  ul {
    list-style: none;
  }
}

.privacy {
  padding: 20px;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    padding-block: 20px;
  }
}
