.admin_productlist__addproduct {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 150px;
    border: 1px solid;
    border-radius: 15px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
}
