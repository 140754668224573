@use "../abstracts/" as *;

.adminCreateProduct {
  &__container {
    width: 95%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    margin: 100px 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    @include breakpoint("sm") {
      padding: 40px;
    }

    @include breakpoint("md") {
      padding: 100px;
    }
  }
}

.product-image-upload__container {
  width: 100%;
  margin: 20px 0px;
  label {
    margin-right: 20px;
  }
}
