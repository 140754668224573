@use "../abstracts/" as *;

.orders_body {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orders_container {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.orders_title {
  margin-top: 30px;
  font-size: 30px;
  font-weight: 600;
}

.orders_listing {
  width: 100%;
  margin-top: 40px;
  box-shadow: 7px 7px 7px gainsboro;
}

.orders_listing div {
  display: flex;
  align-items: center;
}

.orders_listing_header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  font-size: 0.5rem;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid gainsboro;
  background-color: rgb(238, 238, 238);

  @include breakpoint("md") {
    font-size: 1rem;
    height: 70px;
  }
}

.orders_listing_id {
  height: 100%;
  width: 40%;
  margin-left: 20px;
}

.orders_listing_header_rest {
  height: 100%;
  width: 25%;
}

.orders_listing_header_totalPrice {
  width: 15%;
  height: 100%;
  display: flex;
  white-space: nowrap;
}

.orders_listing_header_order_detail {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: color("blue", 700);
}

.orders_listing_header_order_detail div {
  cursor: pointer;
}

.orders_listing_body {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  border: 1px solid gainsboro;
  font-size: 0.5rem;

  @include breakpoint("md") {
    height: 150px;
    font-size: 1rem;
  }
}

.orders_listing_body img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;

  @include breakpoint("md") {
    width: 100px;
    height: 100px;
  }
}

.order_listing_body_description {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  div {
    width: 100%;
  }
}
