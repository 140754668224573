@use "../abstracts/" as *;

.order-detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.order-detail__container {
  width: 80%;
}

.order-detail__title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1rem;
  font-weight: 600;

  @include breakpoint("md") {
    font-size: 3rem;
  }
}

.order-detail__section {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid gainsboro;
  display: flex;
  flex-direction: column;
  font-size: 0.6rem;
  font-weight: 500;

  &__title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @include breakpoint("md") {
    font-size: 1rem;
    &__title {
      font-size: 2rem;
    }
  }
}

.order-detail__section__order-items {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    height: 70px;
    width: 70px;
    object-fit: cover;
    margin-right: 20px;
  }
}
