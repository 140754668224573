@use "../abstracts/" as *;

.search-modal {
  &__header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: "flex-start";
    background-color: $colors-white;
    border-bottom: 1px solid color("neutral", 200);
    font-weight: 600;
    cursor: pointer;

    @include breakpoint("md") {
      padding-left: 20px;
    }

    input {
      height: 90%;
      flex-grow: 1;
      font-size: 1.3rem;
      padding-left: 45px;
      background: url("https://d2c0vv5h4nuw6w.cloudfront.net/icons/search.png")
        no-repeat left;
      background-size: 25px;
      background-position: 10px;
      border-color: transparent;
      &:focus {
        outline: none;
      }
    }
  }

  &__body {
    height: 100vh;
    width: 100%;
    background-color: color("neutral", 100);
    padding-top: 40px;
    padding-left: 70px;
  }

  &__popular-search {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;

    div {
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 7px 15px;
      border: 1px solid $colors-black;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        background-color: $colors-black;
        color: $colors-white;
      }
    }
  }
}
