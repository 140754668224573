$border-color: #dcdcdc;
$header-modifier-highlight: #a11d23;

$background-color: #fefefe;
$colors-black: #000;
$colors-white: #fff;

$colors: (
  "slate": (
    100: rgb(248 250 252),
    200: rgb(241 245 249),
    300: rgb(226 232 240),
    400: rgb(203 213 225),
    500: rgb(148 163 184),
    600: rgb(71 85 105),
    700: rgb(51 65 85),
    800: rgb(30 41 59),
    900: rgb(15 23 42),
  ),
  "gray": (
    100: rgb(243 244 246),
    200: rgb(229 231 235),
    300: rgb(209 213 219),
    400: rgb(156 163 175),
    500: rgb(107 114 128),
    600: rgb(75 85 99),
    700: rgb(55 65 81),
    800: rgb(31 41 55),
    900: rgb(17 24 39),
  ),
  "zinc": (
    100: rgb(244 244 245),
    200: rgb(228 228 231),
    300: rgb(212 212 216),
    400: rgb(161 161 170),
    500: rgb(113 113 122),
    600: rgb(82 82 91),
    700: rgb(63 63 70),
    800: rgb(39 39 42),
    900: rgb(24 24 27),
  ),
  "neutral": (
    100: rgb(245 245 245),
    200: rgb(229 229 229),
    300: rgb(212 212 212),
    400: rgb(163 163 163),
    500: rgb(115 115 115),
    600: rgb(82 82 82),
    700: rgb(64 64 64),
    800: rgb(38 38 38),
    900: rgb(23 23 23),
  ),
  "stone": (
    100: rgb(245 245 244),
    200: rgb(231 229 228),
    300: rgb(214 211 209),
    400: rgb(168 162 158),
    500: rgb(120 113 108),
    600: rgb(87 83 78),
    700: rgb(68 64 60),
    800: rgb(41 37 36),
    900: rgb(28 25 23),
  ),
  "red": (
    100: rgb(254 226 226),
    200: rgb(254 202 202),
    300: rgb(252 165 165),
    400: rgb(248 113 113),
    500: rgb(239 68 68),
    600: rgb(220 38 38),
    700: rgb(185 28 28),
    800: rgb(153 27 27),
    900: rgb(127 29 29),
  ),
  "orange": (
    100: rgb(255 237 213),
    200: rgb(254 215 170),
    300: rgb(253 186 116),
    400: rgb(251 146 60),
    500: rgb(249 115 22),
    600: rgb(234 88 12),
    700: rgb(194 65 12),
    800: rgb(154 52 18),
    900: rgb(124 45 18),
  ),
  "yellow": (
    100: rgb(254 249 195),
    200: rgb(254 240 138),
    300: rgb(253 224 71),
    400: rgb(250 204 21),
    500: rgb(234 179 8),
    600: rgb(202 138 4),
    700: rgb(161 98 7),
    800: rgb(133 77 14),
    900: rgb(113 63 18),
  ),
  "green": (
    100: rgb(220 252 231),
    200: rgb(187 247 208),
    300: rgb(134 239 172),
    400: rgb(74 222 128),
    500: rgb(34 197 94),
    600: rgb(22 163 74),
    700: rgb(21 128 61),
    800: rgb(22 101 52),
    900: rgb(20 83 45),
  ),
  "teal": (
    100: rgb(204 251 241),
    200: rgb(153 246 228),
    300: rgb(94 234 212),
    400: rgb(45 212 191),
    500: rgb(20 184 166),
    600: rgb(13 148 136),
    700: rgb(15 118 110),
    800: rgb(17 94 89),
    900: rgb(19 78 74),
  ),
  "blue": (
    100: rgb(219 234 254),
    200: rgb(191 219 254),
    300: rgb(147 197 253),
    400: rgb(96 165 250),
    500: rgb(59 130 246),
    600: rgb(37 99 235),
    700: rgb(29 78 216),
    800: rgb(30 64 175),
    900: rgb(30 58 138),
  ),
  "indigo": (
    100: rgb(224 231 255),
    200: rgb(199 210 254),
    300: rgb(165 180 252),
    400: rgb(129 140 248),
    500: rgb(99 102 241),
    600: rgb(79 70 229),
    700: rgb(67 56 202),
    800: rgb(55 48 163),
    900: rgb(49 46 129),
  ),
  "purple": (
    100: rgb(243 232 255),
    200: rgb(233 213 255),
    300: rgb(216 180 254),
    400: rgb(192 132 252),
    500: rgb(168 85 247),
    600: rgb(147 51 234),
    700: rgb(126 34 206),
    800: rgb(107 33 168),
    900: rgb(88 28 135),
  ),
  "pink": (
    100: rgb(252 231 243),
    200: rgb(251 207 232),
    300: rgb(249 168 212),
    400: rgb(244 114 182),
    500: rgb(236 72 153),
    600: rgb(219 39 119),
    700: rgb(190 24 93),
    800: rgb(157 23 77),
    900: rgb(131 24 67),
  ),
);
