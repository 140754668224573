@use "../abstracts/" as *;

.not_found_container {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-size: 3rem;
    @include breakpoint("md") {
      font-size: 8rem;
    }
  }

  &__description {
    font-size: 1rem;
    margin-top: 40px;
    margin-bottom: 40px;

    @include breakpoint("md") {
      font-size: 1.5rem;
    }
  }

  &__backToHome {
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 10px;
    background-color: black;
    cursor: pointer;
  }
}
