@use "../abstracts/" as *;

body {
  margin: 0px auto;
  padding: 0;
  min-width: 320px;
  max-width: 1980px;

  font-family: $ff-intel;
  background-color: $background-color;
  color: $colors-black;
  transition: background-color 0.4s ease-in-out;
}
